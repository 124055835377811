import React from "react";

const UpdatePage = () => {
  return (
    <div className="container my-5">
      <h2 className="abstract_head">welcome</h2>
      <div>
        <p>Dear Delegates and students,</p>
        <p>
          We are pleased to extend a warm welcome to each one of you for our
          forthcoming ICD conference 2025. We are thrilled to have you join us
          for what promises to be an exciting and enriching event. The
          participants are requested to report by 8am on 22nd for seamless
          registration procedures at T.P Ganesan Auditorium. We have an array of
          start studded speakers for our scientific events on 22nd and 23rd of
          February. The inaugural ceremony is scheduled at 5:30pm on 22nd. The
          convocation and award ceremony are scheduled for Sunday at 10:30am.
          Please visit our website for further announcements.
        </p>
        <p>Warm regards,</p>
        <p>Organising committee ICD 2025</p>
        <p>SRM Kattankulathur Dental College,</p>
        <p>SRM Institute of Science and Technology</p>
      </div>
      <div className="mt-4 d-flex align-items-center justify-content-between flex-column flex-lg-row gap-2">
        <a
          href="./Inauguration-Invitation-ICD-2025.pdf"
          className=" p-3 rounded"
          style={{
            textDecoration: "none",
            color: "white",
            backgroundColor: "#147745",
          }}
          target="_blank"
        >
          Inaugural Invitation{" "}
        </a>
        <a
          href="./Award-function-Invitation-23rd.pdf"
          className=" p-3 rounded"
          style={{
            textDecoration: "none",
            color: "white",
            backgroundColor: "#147745",
          }}
          target="_blank"
        >
          Special awards ceremony
        </a>
      </div>
    </div>
  );
};

export default UpdatePage;
