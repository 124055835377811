import React from "react";
import { Link } from "react-router-dom";
import "./scientificpresentation.css";

const ScientificPresentation = () => {
  return (
    <div className="container scientificcontainer px-2">
      <h1>Scientific Presentation</h1>

      <div className="d-flex align-items-center justify-content-around p-2 gap-2 flex-md-row flex-column">
        <div className="border border-1 card outline-none border-none shadow p-3">
          <div className="card-body ">
            <h3 className="card-title pb-3">
              STUDENT / DELEGATE PAPER PRESENTATION
            </h3> 
            <a
              href="./Scientific Paper Schedule  (Day 1)_22.02.2025.pdf"
              className="py-3 mx-auto px-2 rounded"
              style={{
                textDecoration: "none",
                color: "white",
                backgroundColor: "#147745",
              }}
              target="_blank"
            >
              Scientific Paper Schedule
            </a>
          </div>
        </div>
        <div className="border border-1 card outline-none border-none shadow p-3">
          <div className="card-body ">
            <h3 className="card-title pb-3">
              STUDENT / DELEGATE POSTER PRESENTATION
            </h3>

            <a
              href="./Scientific Poster Schedule (Day 1)_22.02.2025.pdf"
              className="py-3 px-2 rounded"
              style={{
                textDecoration: "none",
                color: "white",
                backgroundColor: "#147745",
              }}
              target="_blank"
            >
              Scientific Poster Schedule
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScientificPresentation;
