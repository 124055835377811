import React from 'react'
import { Link } from 'react-router-dom'

const HomeBanner = () => {
  return (
          <section className="HomeContaier">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex flex-lg-row flex-column justify-content-between">
                  <div className="col-lg-6 ps-lg-5">
                    <div className="">
                      <p className="line_head_para p-0 m-0">ICD 2025 EVENT</p>
                    </div>
                    <div className="d-flex flex-lg-row flex-md-row flex-column align-items-center justify-content-between my-3">
                      <img
                        src="../img/home/HomeContent/healthLogo.webp"
                        className="img-fluid"
                        alt=""
                      />
                      <p className="text-white p-0 m-0 logoHead">
                        In collaboration with{" "}
                      </p>
                    </div>
                    <div className="my-4">
                      <h1 className="collegeHead">
                        SRM KATTANKULATHUR DENTAL COLLEGE
                      </h1>
                      <p className="text-white collegePara p-0 m-0">
                        International College of Dentistry (ICD) Section VI – India,
                        Sri Lanka, and Nepal{" "}
                      </p>
                    </div>
                    <div>
                      <Link
                        className=""
                        target="_blank"
                        // to="https://forms.eduqfix.com/icddentist/add"
                      >
                        <button disabled className="registerBtn my-3">Register now</button>
                      </Link>
    
                      <p className="registerPara p-0 m-0">
                        You can register for case participation and submission in
                        the Epistate Health – Epistate Health. For Registration
                        related queries please contact the whatapp provided in the
                        section 8 for Contact Information for Registration and
                        Product Queries
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-center flex-lg-row flex-md-column">
                    <div className="col-lg-12 col-md-12 col-12 d-flex align-items-lg-end align-items-center justify-content-evenly flex-lg-column flex-md-row flex-column">
                      <div className="whiteBox">
                        <p className="whiteBoxHead p-0 m-0">
                          Announces the Endodontic Case Contest for the Upcoming ICD
                          2025 Event
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between gap-4 flex-lg-row flex-md-column-reverse flex-column-reverse">
                        <div className="d-flex flex-column align-items-start ">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              className="img-fluid"
                              src="../img/home/HomeContent/star.webp"
                              alt=""
                            />
                            <img
                              className="img-fluid"
                              src="../img/home/HomeContent/star.webp"
                              alt=""
                            />
                            <img
                              className="img-fluid"
                              src="../img/home/HomeContent/star.webp"
                              alt=""
                            />
                            <img
                              className="img-fluid"
                              src="../img/home/HomeContent/star.webp"
                              alt=""
                            />
                            <img
                              className="img-fluid"
                              src="../img/home/HomeContent/star.webp"
                              alt=""
                            />
                          </div>
                          <div>
                            <p className="p-0 m-0 startext">
                              100+ members Register
                            </p>
                          </div>
                        </div>
                        <div>
                          <img
                            src="../img/home/HomeContent/peopleImg.webp"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default HomeBanner