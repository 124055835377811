import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Nav.css";

const Nav = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const HandleMenu = () => {
    setOpenMenu(false);
  };
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  console.log(pathname);
  return (
    <>
      <nav className="navbar navbar-expand-lg fixed sticky-top desktop_navbar">
        <div className="container-fluid col-12">
          <Link className="navbar-brand" to="/">
            <img
              src="./img/home/logo.webp"
              alt="ICD International College of Dentists (REGD)"
              className="img-fluid logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse d-flex justify-content-start"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${pathname === "" ? "active" : ""}`}
                  to={"/"}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "about" ? "active" : ""}`}
                  to={"/about"}
                >
                  About
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "committee" ? "active" : ""
                  }`}
                  to={"/committee"}
                >
                  Committee
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "scientificprogram" ? "active" : ""
                  }`}
                  to={"/scientificprogram"}
                >
                  Scientific Program
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "scientificpresentation" ? "active" : ""
                  }`}
                  to={"/scientificpresentation"}
                >
                  Scientific Presentations
                </Link>
              </li>
              {/* <li className="nav-item mx-1">
                <Link
                  className="nav-link"
                  target="_blank"
                  to="https://forms.eduqfix.com/icddentist/add"
                >
                  Registration
                </Link>
              </li> */}
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "Abstracts" ? "active" : ""
                  }`}
                  to={"/Abstracts"}
                >
                  Abstract Submission
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "accommodation" ? "active" : ""
                  }`}
                  to={"/PriceWinner"}
                >
                Price Winner
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "update" ? "active" : ""
                  }`}
                  to={"/update"}
                >
                  Updates and Announcement
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${pathname === "venue" ? "active" : ""}`}
                  to={"/gallery"}
                >
                  Gallery
                </Link>
              </li>
              <li className="nav-item mx-1">
                <Link
                  className={`nav-link ${
                    pathname === "guidelines" ? "active" : ""
                  }`}
                  to={"/guidelines"}
                >
                  Guidelines for paper/poster
                </Link>
              </li>
           
              <li className="nav-item mx-1">
                <Link className="nav-link" to={"/contact"}>
                  Contact
                </Link>
              </li>
              <li>
                <button className="btn btn-success ">
                  <a href="./3.pdf" className="nav-link">
                    Download Souvenir
                  </a>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mobile_container">
        <div className={`header_mobile ${openMenu ? "menu-open" : null}`}>
          <div className="container-fluid col-lg-10">
            <div className="d-flex justify-content-between align-items-center mobile_pallete">
              <div>
                <Link to="/home">
                  <img
                    src="./img/home/logo.webp"
                    className="img-fluid logo ml-5"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="icon-container mr-5">
                <i
                  className="bi bi-list hamburger"
                  onClick={() => setOpenMenu(!openMenu)}
                ></i>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <ul className="menu">
              <li className="menu-item">
                <Link to={"/"} onClick={() => HandleMenu()}>
                  Home
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/"} onClick={() => HandleMenu()}>
                  Welcome
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/committee"} onClick={() => HandleMenu()}>
                  Committee
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/scientificprogram"} onClick={() => HandleMenu()}>
                  Scientific Program
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  to={"/scientificpresentation"}
                  onClick={() => HandleMenu()}
                >
                  Scientific Presentations
                </Link>
              </li>
              {/* <li className="menu-item">
                <Link
                  to={"https://forms.eduqfix.com/icddentist/add"}
                  onClick={() => HandleMenu()}
                >
                  Registration
                </Link>
              </li> */}
              <li className="menu-item">
                <Link to={"/Abstracts"} onClick={() => HandleMenu()}>
                  Abstract Submission
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/PriceWinner"} onClick={() => HandleMenu()}>
             Price Winner
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/guidelines"} onClick={() => HandleMenu()}>
                  Guidelines for paper/poster
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/update"} onClick={() => HandleMenu()}>
                  Updates and Announcement
                </Link>
              </li>
              <li className="menu-item">
                <Link to={"/gallery"} onClick={() => HandleMenu()}>
                  Gallery
                </Link>
              </li>
       
              <li className="menu-item">
                <Link to={"/contact"} onClick={() => HandleMenu()}>
                  Contact
                </Link>
              </li>
              <div
                className="close-icon d-flex justify-content-end  w-100"
                onClick={() => setOpenMenu(false)}
              >
                <img
                  className={"img-fluid m-2"}
                  width={"40px"}
                  src="https://img.icons8.com/?size=100&id=3062&format=png&color=000000"
                  alt=""
                />
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
