import { Link } from "react-router-dom";
import "./abstract.css";
const Abstracts = () => {
  return (
    <div className="AbstractContent">
      <div className="container-fluid col-lg-10 col-md-10 col-11">
        <div className="row d-flex justify-content-center">
          <h1 className="abstract_head text-center">Abstracts Submission</h1>
          <h1 className="abstract_head1">Student Paper / Poster </h1>
          <h1 className="abstract_head2">General Guidelines </h1>
          <ul className="guidlines_list ms-5">
            <li className="guidline_listItems">
              Presenter must be registered delegate for ICD conference 2025{" "}
            </li>
            <li className="guidline_listItems">
              Abstract should be original and should not be published or
              presented anywhere in the past.{" "}
            </li>
            <li className="guidline_listItems">
              Only one presenter is allowed for paper presentation.{" "}
            </li>
            <li className="guidline_listItems">
              Abstracts received which are not following submission guidelines
              and received after the deadline will not be considered unless the
              deadline is extended.{" "}
            </li>
            <li className="guidline_listItems">
              After the abstract submission, you will be sent an automatic
              e-mail confirming your successful submission. E-mail will also be
              used to confirm acceptance/rejection. If you do not receive the
              confirmation e-mail please contact the scientific congress through
              email.{" "}
            </li>
            <li className="guidline_listItems">
              The abstract needs to be submitted as per the abstract submission
              template provided below.{" "}
            </li>
            <li className="guidline_listItems">
              Submission file should be renamed using the following format -
              Conference Registration number/Category E.g. S719/Student Paper{" "}
            </li>
          </ul>
          <h1 className="abstract_head2">
            Abstract Template for Paper / Poster Presentation
          </h1>
          <p className="abstract_title">Abstract Title:</p>
          <p className="abstract_content">
            Title of Presentation in Arial/ Times New Roman font: Size 14 point.
            Each word should begin with a capital letter except transition
            words; Title should not be longer than 20 words.
          </p>
          <p className="abstract_title">Abstract Body: </p>
          <p className="abstract_content">
            Title of Presentation in Arial/ Times New Roman font: Size 14 point.
            Each word should begin with a capital letter except transition
            words; Title should not be longer than 20 words.
          </p>
          <h1 className="abstract_head2">
            Structured Abstract for Original Research:
          </h1>
          <p className="abstract_content">Background/ Purpose</p>
          <p className="abstract_content">Aims & Objective</p>
          <p className="abstract_content">
            <span style={{ fontWeight: 600 }}>Methods:</span> Methods, Materials
            and Analytical procedure
          </p>
          <p className="abstract_content">
            <span style={{ fontWeight: 600 }}>Results:</span> Summary of the
            results in sufficient detail [ “Results will be discussed”– Not
            recommended]
          </p>
          <p className="abstract_content">
            <span style={{ fontWeight: 600 }}>Conclusion:</span> The total
            number of words for abstract – 250 Words not including title, or
            section headings.
          </p>
          <h1 className="abstract_head2">
            Structured Abstract for Case Reports/Case Series:
          </h1>
          <p className="abstract_content">
            <span style={{ fontWeight: 600 }}>Introduction:</span> Background of
            the disease or condition
          </p>
          <p className="abstract_content">
            Clinical Case [Key points- Clinical Findings, Diagnostic evaluation,
            treatment and Follow-Up]{" "}
          </p>
        </div>
        <p className="abstract_content">
          <span style={{ fontWeight: 600 }}>Conclusion:</span>Conclusion and
          Clinical Implications
        </p>
        <p className="abstract_content">
          The total number of words for abstract – 150 Words Not including
          title, or section headings.{" "}
          <span style={{ fontWeight: 600 }}>
            Please note that incomplete abstracts will not be accepted{" "}
          </span>
        </p>
        <p className="abstract_content" style={{ fontWeight: 600 }}>
          Un-structured Abstract for Review Papers
        </p>
        <p className="abstract_content" style={{ fontWeight: 600 }}>
          The total number of words for abstract – 150 Words
        </p>
        <div className="mt-3 py-2">
          <Link target="_blank" to="https://forms.gle/fKG7qJfGRN9PScWc8" className="w-75 m-sm-2 ">
            <button className="btn btn-success mt-3 mt-sm-0" >
              Abstract submission
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Abstracts;
