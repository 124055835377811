import React, { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { setValue } = useAuth();
  const route = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        await fetch("https://icd-srm.azurewebsites.net/api/login", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }).then((res) => {
          if (res.status === 200) {
            setValue(true);
            route("/dashboard");
          } else {
            console.log("Login failed");
            setValue(false);
          }
          res = res.json();
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  async function handleSubmit(event) {
    event.preventDefault();

    await fetch("https://icd-srm.azurewebsites.net/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        email,
        password,
      }),
    }).then((res) => {
      if (res.status === 200) {
        setValue(true);
        route("/dashboard");
      } else {
        console.log("Login failed");
        setValue(false);
      }
      res = res.json();
    });
  }
return (
    <div className="p-2">
        <div className="d-flex flex-column p-4 align-items-center justify-content-center" style={{ minHeight:"60vh", margin: "auto" }}>
            <h1> Login</h1>
            <form className="d-flex flex-column p-3 gap-2" onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Email"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    className="w-full btn btn-primary text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                    type="submit"
                    disabled={!email || !password}
                >
                    Login
                </button>
            </form>
        </div>
    </div>
);
};

export default AdminLogin;
