import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import { Link } from "react-router-dom";
import HomeBanner from "../../component/Home/HomeBanner";

const Home = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
    pauseOnHover: false,
  };
  var txtsettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <>
      <section id="homepage-banner" className="animatediv">
        <div className="col-12 banner_top ">
          <div className="row">
            <h2 className="text-center mb-3 mt-4 home_banner_head">
              Integrating Research to Clinical Dentistry
            </h2>
            <marquee
              behavior="scroll"
              className="p-2 mb-3"
              direction="left"
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                color: "rgb(255, 0, 0)",
              }}
            >
              For any queries related to paper / poster presentation, contact
              Dr. Sathyakumar 9500038843
            </marquee>
            <div className="col-5 ">
              <div className="d-flex w-100 h-100 align-items-start justify-content-center">
                <div className="py-2">
                  <img
                    src="./img/home/Capture.JPG"
                    alt=""
                    className="pdf_images"
                  />
                </div>
              </div>
            </div>
            <div className="col-7 h-100 d-flex flex-column justify-content-between align-items-center pt-4 pb-3">
              <div className="slider1 w-100">
                <Slider {...settings}>
                  {Array.from({ length: 4 }).map((_, i) => {
                    return (
                      <div
                        className=""
                        key={i}
                        style={{ objectFit: "contain" }}
                      >
                        <img
                          src={`./img/home/${i + 1}.png`}
                          className="img-fluid d-block slider_img center-block mx-auto rounded-3"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="slider2 w-100 ">
                <Slider {...settings2}>
                  {Array.from({ length: 2 }).map((_, i) => {
                    return (
                      <div key={i} className="">
                        <img
                          src={`./img/home/${i + 5}.png`}
                          className="img-fluid d-block slider_img2 center-block mx-auto"
                          style={{ objectFit: "cover" }}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="banner_bottom">
          <div className="d-flex align-items-center justify-content-around">
            <p className="fw-bold">
              <span className="text-primary">Date: </span>22nd February and 23rd
              February 2025
            </p>
            <p className="fw-bold">
              <span className="text-primary">Venue: </span>SRM Kattankulathur
              Dental College, SRMIST, Chennai, Tamilnadu, India
            </p>
          </div>
        </div>
      </section>
      <section id="banner-mobile">
        <div className="mobile-slider">
          <div className="w-100 py-2 px-2" style={{ width: "100%" }}>
            <img src="./img/home/Capture.JPG" className="img-fluid" alt="" />
          </div>
        </div>
        <div className="w-100 mt-3 p-2">
          <div className="p-1">
            <Slider {...txtsettings}>
              <p className="text-white text-center fw-bold fs-3 px-1 ">
                Immerse yourself for a remarkable and exclusive opportunity and
                a truly distinctive experience
              </p>
              <p className="text-white text-center fw-bold fs-3 px-1 ">
                3 days of scientific extravaganza
              </p>
              <p className="text-white text-center fw-bold fs-3 px-1">
                Open to Undergraduates, Postgraduates, Faculty/practioner
              </p>
            </Slider>
          </div>
          <p
            className="fw-bold p-1 text-center"
            style={{
              borderBottom: "solid 2px yellow",
            }}
          >
            Stay tuned for further captivating and exhilarating news.
          </p>
        </div>
        <div
          className="w-100 bg-white py-2 px-1"
          style={{ boxShadow: "0px 3px 2px 0px rgba(0, 0, 0, 0.24)" }}
        >
          <div className="d-flex flex-column py-2 align-items-center justify-content-around">
            <p className="fw-bold">
              <span className="text-primary">Date: </span>22nd February and 23rd
              February 2025
            </p>
            <p className="fw-bold">
              <span className="text-primary">Venue: </span>SRM Kattankulathur
              Dental College,
              <br /> SRMIST, Chennai, Tamilnadu, India
            </p>
          </div>
        </div>
      </section>
      <HomeBanner />
      <section className="HomeContaierEligibilitytop">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 col-md-10 col-12 d-flex flex-lg-row flex-column align-items-center">
              <div className="col-lg-6">
                <img
                  src="../img/home/HomeContent/contestImg.webp"
                  className="img-fluid d-block my-5"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <p className="line_head_para2 p-0 m-0">
                  Contest Theme and Objective
                </p>
                <p className="contestHead my-4">
                  <span className="fw-bold contestHeadspan">Theme : </span>
                  "Complex / Challenging Root Canal Cases systems"
                </p>
                <p className="contestHead">
                  <span className="fw-bold contestHeadspan">Objective : </span>
                  "Highlight advanced techniques, promote learning, and
                  recognize excellence in root canal treatments"
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="HomeContaierEligibilitybottom">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 col-md-10 col-12 d-flex flex-lg-row flex-column align-items-center flex-lg-row flex-md-column-reverse">
              <div className="col-lg-6">
                <p className="line_head_para2 p-0 m-0">Eligibility Criteria</p>
                <p className="contestHead my-4">
                  <span className="fw-bold contestHeadspan">
                    Eligibility :{" "}
                  </span>
                  "Open to general dentists, specialists, and students"
                </p>
                <p className="contestHead">
                  <span className="fw-bold contestHeadspan">Beginner : </span>
                  "Undergraduate and postgraduate students; early dental
                  practitioners ( {"<"}3 years of practice)."
                </p>
                <p className="contestHead">
                  <span className="fw-bold contestHeadspan">Advanced : </span>
                  "Dental practitioners with {"<"} 3 years of experience."
                </p>
              </div>
              <div className="col-lg-6">
                <img
                  src="../img/home/HomeContent/contestImg.webp"
                  className="img-fluid d-block ms-auto my-5"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-10">
              <div className="d-flex align-items-center justify-content-center">
                <div className="whiteBox2">
                  <p className="contestHead my-4">
                    <span className="fw-bold contestHeadspan">Note: </span> Only
                    cases performed using Epident V+ Gold Files will be
                    considered for review and evaluation (For purchase of files
                    please contact WhatsApp:{" "}
                    <span className="fw-bold contestHeadspan">
                      {" "}
                      +918296178204
                    </span>
                    )
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="HomeContaierCase">
        <div className="CaseReportContainer col-lg-12 col-md-12 col-12 d-flex flex-lg-row flex-column align-items-center justify-content-center">
          <div className="col-lg-6 CaseReportContainerLeft ">
            <div className="CaseReportContainerLeftInner">
              <h1 className="collegeHead"> Submission Guidelines</h1>
              <div className="d-flex align-items-center justify-content-start my-3 gap-3">
                <div>
                  <img src="../img/home/HomeContent/peopleImg.webp" alt="" />
                </div>
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex align-items-center gap-2">
                    <img
                      className="img-fluid"
                      src="../img/home/HomeContent/star.webp"
                      alt=""
                    />
                    <img
                      className="img-fluid"
                      src="../img/home/HomeContent/star.webp"
                      alt=""
                    />
                    <img
                      className="img-fluid"
                      src="../img/home/HomeContent/star.webp"
                      alt=""
                    />
                    <img
                      className="img-fluid"
                      src="../img/home/HomeContent/star.webp"
                      alt=""
                    />
                    <img
                      className="img-fluid"
                      src="../img/home/HomeContent/star.webp"
                      alt=""
                    />
                  </div>
                  <div>
                    <p className="p-0 m-0 startext">100+ members Register</p>
                  </div>
                </div>
              </div>
              <Link
                className=""
                target="_blank"
                // to="https://forms.eduqfix.com/icddentist/add"
              >
                <button disabled="true" className="registerBtn my-3">
                  Register now
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 CaseReportContainerRight  d-flex align-items-center justify-content-center">
            <div className="col-10">
              <div className="caseReportContainerRightInnerBox">
                <p className="line_head_para2 p-0 m-0">
                  {" "}
                  Case Report Requirements
                </p>
                <ul className="caseUl">
                  <li>Patient's clinical history (anonymized for privacy)</li>
                  <li>
                    Pre-operative and post-operative radiographs/images/clinical
                    photos
                  </li>
                  <li> Innovative techniques or tools used</li>
                  <li> Rubber dam application is compulsory</li>
                </ul>
              </div>
              <div className="caseReportContainerRightInnerBox">
                <p className="line_head_para2 p-0 m-0"> Format</p>
                <ul className="caseUl">
                  <li>
                    <span className="fw-bold contestHeadspan">
                      Case Summary :{" "}
                    </span>{" "}
                    Submit a Word document (maximum 1,000 words) with relevant
                    clinical and radiographic images.
                  </li>
                  <li>
                    <span className="fw-bold contestHeadspan">
                      Annotations :{" "}
                    </span>
                    <ul>
                      <li>
                        Highlight key aspects such as canals, obturation
                        quality, or anatomical challenges
                      </li>
                      <li>
                        Highlight key aspects such as canals, obturation
                        quality, or anatomical challenges
                      </li>
                      <li>
                        Include your name and invoice number as the case number
                      </li>
                      <li>
                        Optionally, provide markings or captions for clarity
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="fw-bold contestHeadspan">Optional : </span>{" "}
                    Include a video or presentation (5–10 minutes)
                  </li>
                  <li>
                    <span className="fw-bold contestHeadspan">
                      {" "}
                      Additional Requirement :{" "}
                    </span>{" "}
                    Include a photo of yourself displaying the Epident V+ Gold
                    Files along with the case summary.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10 col-md-10 col-12 d-flex flex-lg-row flex-column align-items-center justify-content-between">
              <div className="col-lg-6">
                <img
                  src="../img/home/HomeContent/Criteria.webp"
                  className="img-fluid d-block my-lg-5"
                  alt=""
                />
              </div>
              <div className="col-lg-5">
                <p className="line_head_para2 p-0 m-0">Evaluation Criteria </p>
                <ul className="caseUl">
                  <li> Clinical complexity and challenges`</li>
                  <li>Diagnostic approach and problem-solving skills</li>
                  <li> Quality of treatment and outcomes.</li>
                  <li> Innovative methods or technology applied.</li>
                  <li> Overall presentation and documentation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="AwardsContainer">
        <div className="container-fluid">
          <div className="row d-flex align-iterms-center justify-content-center">
            <div className="col-lg-10 d-flex align-items-center justify-content-center flex-lg-row flex-md-column flex-column">
              <div className="col-lg-6 col-md-10">
                <div className="mb-4">
                  <h1 className="line_head_para2">Awards and Recognition</h1>
                  <ul className="caseUl">
                    <li> Beginner Category </li>
                    <li>Advanced Category</li>
                  </ul>
                </div>
                <div>
                  <ul className="caseUl">
                    <li>5 X Loupes Titanium frame – 150000 X 2 = 300000 </li>
                    <li>Epident Smart Plus Endomotor - 55000 X 4 = 220000 </li>
                    <li>Epident Smart Endomotor -30000 X 2 = 60000 </li>
                    <li>Obturation Pen and Gun 35000 X 3 = 105000 </li>
                    <li>And many more worth of 725000 INR Prizes</li>
                    <li>Winners will also receive digital badges</li>
                  </ul>
                </div>
                <div>
                  <h1 className="line_head_para2"> Additional Recognition</h1>
                  <ul className="caseUl">
                    <li>
                      {" "}
                      Acknowledgment on professional platforms such as dental
                      journals and the Epispace blog.
                    </li>
                    <li>
                      {" "}
                      Social media recognition: Share your case using hashtags
                      #Epident, #Epistatehealth, #EpidentV+GoldFiles
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="col-lg-11  mx-auto gridBox">
                  <div className="gridChild">
                    <p>Category</p>
                  </div>
                  <div className="gridChild">
                    <p>Beginner Category</p>
                  </div>
                  <div className="gridChild">
                    <p>Advanced Category</p>
                  </div>
                  <div className="gridChild">
                    <p>First Prize</p>
                  </div>
                  <div className="gridChild">
                    <p>5 X loupes Titanium frame and Epident Smart Endomotor</p>
                  </div>
                  <div className="gridChild">
                    <p>5 X loupes Titanium frame and Epident Smart Endomotor</p>
                  </div>
                  <div className="gridChild">
                    <p>Second Prize</p>
                  </div>
                  <div className="gridChild">
                    <p>5 X loupes Titanium frame and Epident Smart Endomotor</p>
                  </div>
                  <div className="gridChild">
                    <p>5 X loupes Titanium frame and Epident Smart Endomotor</p>
                  </div>
                  <div className="gridChild">
                    <p>Third Prize</p>
                  </div>
                  <div className="gridChild">
                    <p>5 X loupes Titanium frame and Epident Smart Endomotor</p>
                  </div>
                  <div className="gridChild">
                    <p>5 X loupes Titanium frame and Epident Smart Endomotor</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="RequirementsContainer">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 mx-auto">
              <h2 className="RequirementsHead">
                IOPA Radiograph Requirements for Submissionsat
              </h2>
            </div>
          </div>
          <div className="row d-flex align-iterms-center justify-content-center">
            <div className="col-lg-10 d-flex align-items-center justify-content-center flex-lg-row flex-md-column flex-column">
              <div className="col-lg-6 col-md-10 mb-lg-0 mb-4">
                <div className="col-lg-10 mx-auto greyBox">
                  <h1 className="line_head_para2">Periapical Visibility:</h1>
                  <ul className="caseUl">
                    <li>
                      {" "}
                      The IOPA must clearly display at least 3 mm beyond the
                      apex of the tooth.
                    </li>
                    <li>
                      {" "}
                      Ensure no artifacts obstruct the critical periapical area
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="col-lg-10 mx-auto greyBox">
                  <h1 className="line_head_para2">Radiograph Quality:</h1>
                  <ul className="caseUl">
                    <li>Radiographs should be a good quality</li>
                    <li>Images must be free from distortion or blurring.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex align-iterms-center justify-content-center mt-5">
            <div className="col-lg-10 d-flex align-items-center justify-content-center flex-lg-row flex-md-column flex-column">
              <div className="col-lg-6 col-md-10 mb-lg-0 mb-4">
                <div className="col-lg-10 mx-auto greyBox">
                  <h1 className="line_head_para2">Multiple Angulations:</h1>
                  <ul className="caseUl">
                    <li>
                      Submit at least two angulations (e.g., mesial, distal, or
                      standard) to depict anatomy accurately, confirm working
                      lengths, and evaluate obturation.
                    </li>
                    <li>Label angulations clearly</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-10">
                <div className="col-lg-10 mx-auto greyBox">
                  <h1 className="line_head_para2">Submission Format:</h1>
                  <ul className="caseUl">
                    <li>
                      Acceptable formats: High-resolution JPEG, PNG, or PD
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ContactInformationContainer">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-11 blackBox d-flex justify-content-around align-items-center flex-lg-row flex-md-column flex-column">
              <div className="col-lg-6">
                <h2 className="footerContentHead">
                  Contact Information for Registration and Product Queries
                </h2>
                <ul className="footerContentUl">
                  <li>
                    Dr. Niharika Jain Gupta, MDS –{" "}
                    <span className="grayText">
                      Director, Scientific Affairs, Epistatic Health Singapore.
                      WhatsApp :
                    </span>{" "}
                    + 91 9424392599
                  </li>
                  <li>
                    Dr. Rekha Mani, MDS –{" "}
                    <span className="grayText">
                      Associate Professor, SRM Kattankulathur Dental college,
                      Advisor, Epistatic Health Singapore. WhatsApp:
                    </span>{" "}
                    +91 9444810543
                  </li>
                  <li>
                    Dr. Vikas Singh, BDS –{" "}
                    <span className="grayText">
                      Clinical Coordinator, Epistatic Health Singapore.
                      WhatsApp:
                    </span>{" "}
                    +91 7318448173
                  </li>
                </ul>
                <h2 className="footerContentHead">
                  Contact information for Product Purchase
                </h2>
                <ul className="footerContentUl">
                  <li>
                    Epident - <span className="grayText">WhatsApp :</span> +
                    918296178204
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-12 col-12 FooterImgMainParent">
                <div className="col-lg-12 footerImgParent">
                  <div className="CaseReportContainerLeftInner2">
                    <div className="d-flex align-items-center justify-content-center flex-lg-row flex-column my-3 gap-3">
                      <div>
                        <img
                          src="../img/home/HomeContent/peopleImg.webp"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            className="img-fluid"
                            src="../img/home/HomeContent/star.webp"
                            alt=""
                          />
                          <img
                            className="img-fluid"
                            src="../img/home/HomeContent/star.webp"
                            alt=""
                          />
                          <img
                            className="img-fluid"
                            src="../img/home/HomeContent/star.webp"
                            alt=""
                          />
                          <img
                            className="img-fluid"
                            src="../img/home/HomeContent/star.webp"
                            alt=""
                          />
                          <img
                            className="img-fluid"
                            src="../img/home/HomeContent/star.webp"
                            alt=""
                          />
                        </div>
                        <div>
                          <p className="p-0 m-0 startext">
                            100+ members Register
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link
                      className=""
                      target="_blank"
                      // to="https://forms.eduqfix.com/icddentist/add"
                    >
                      <button disabled="true" className="registerBtn my-3">
                        Register now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
