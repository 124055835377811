import { useEffect, useState } from "react";

const PGupload = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setError("");
    setMessage("");
  };

  useEffect(() => {
    if(error) setTimeout(() => setError(""), 5000)
    if(message) setTimeout(() => setMessage(""), 5000)
  },[error,message])
  const handleUpload = async (event) => {
    event.preventDefault();
    if (!file) {
      setError("Please select a file first");
      return;
    }
    try {
      setUploading(true);
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const base64String = reader.result.split(",")[1];
          const response = await fetch("https://icd-srm.azurewebsites.net/api/set_pg", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              fileName: file.name,
              fileType: file.type,
              fileData: base64String,
            }),
          });

          const result = await response.json();

          if (!response.ok) {
            throw new Error(result.error || "Upload failed");
          }

          setMessage("File uploaded successfully!");
          setFile(null);
          const fileInput = document.querySelector('input[type="file"]');
          if (fileInput) fileInput.value = "";
        } catch (err) {
          setError(err.message || "Failed to upload file");
          console.error("Upload error:", err);
        }
      };

      reader.onerror = () => {
        setError("Failed to read file");
      };

      reader.readAsDataURL(file);

    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="">
    
      <div  className="mb-4 d-flex gap-3 flex-column align-items-center">
        <label htmlFor="pgupload" style={{ cursor: "pointer" }}>
          <img src="/img/upload.png" alt="" style={{  transition: "transform 0.2s" }}
            onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
            onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}/>
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          accept=".xlsx,.xls"
          disabled={uploading}
          id="pgupload"
          className="mb-2 d-none"
        />
        <button
          onClick={handleUpload}
          disabled={!file || uploading}
        className="btn btn-primary text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
        >
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </div>

      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}
      
      {message && (
        <div className="alert alert-success">
          {message}
        </div>
      )}
    </div>
  );
};

export default PGupload;
