import React, { createContext, useState,useContext  } from 'react';

export const BooleanContext = createContext({
    value: false,
    setValue: () => {}
});

export const AuthProvider = ({ children }) => {
    const [value, setValue] = useState(false);

    return (
        <BooleanContext.Provider value={{ value, setValue }}>
            {children}
        </BooleanContext.Provider>
    );
};

export const useAuth = () => useContext(BooleanContext);