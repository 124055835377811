import React from 'react'
import guImg from "../../../src/assets/guidelinesImg.jpg"
const Guidelines = () => {
  return (
    <>
    <div className='container'>
        <div className='mx-auto'>
    <img src={guImg} className='img-fluid center-block mx-auto d-block' alt="" />
        </div>
    </div>
    </>
  )
}

export default Guidelines