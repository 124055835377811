import React from "react";
import "./contact.css";
const Contact = () => {
  return (
   <>
   <section className="animatediv">
   <div className="contact_background pt-5 pb-5">
        <div className="container-fluid col-lg-12 col-12">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <h2 className="contact_head">CONTACT</h2>
              <p className="mt-4 contacts_para">
                Please feel free to contact us at any time with any questions or
                concerns you may have. We will do our best to respond to your
                inquiry as quickly as possible.
              </p>
            </div>
          </div>
          <div className="places_back mt-5">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-8 col-md-12 col-12 mt-lg-0 mt-5 center-block d-block mx-auto">
                <h2 className="places_head">FOR MORE DETAILS CONTACT</h2>
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Organizing secretary: Dr. K T Mahesh
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9840363642
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919840363642" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div> 
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Scientific Chairman: Dr. C. DEEPAK
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9840060745
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919840060745" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Registration : Dr. Mirnalini Selvakumar 
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9940168779
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919940168779" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Registration : Dr. S. Aravinthan 
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9095370301
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919095370301" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
              
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Trade & Sponsorship: Dr. Deenadayalan.P 
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9840895100
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919840895100" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Accommodation: Dr. A Victor Samuel
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9841610525
                    </p>
                  </div>
 
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919841610525" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
                <div className="row d-flex align-items-center justify-content-around my-lg-4 my-5">
                  <div className="col-lg-4 col-md-5 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      <span className="yellow_para">
                      Transport: Dr. Aravindhan.R
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12">
                    <p className="contact_paras mt-2 text-lg-start text-center">
                      {" "}
                      +91 9790801260
                    </p>
                  </div>
 
                  <div className="col-lg-4 col-md-4 col-12">
                    <a href="tel:+919790801260" className="linkALL">
                      <button
                        type="button"
                        className="btn_direction1 center-block d-block mx-auto mx-lg-0"
                      >
                        <i className="bi bi-telephone-fill mx-2"></i> Call now{" "}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </section>
   </>
  );
};

export default Contact;
