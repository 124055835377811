import React from 'react'

const PriceWinner = () => {
  return (
    <div className='container-fluid col-10'>
    <div className='row py-5'>
     <div className='col-lg-6 col-12'>
        <div className='card p-3'>
            <h4 className='text-center'>INTERNATIONAL COLLEGE OF DENTISTS (ICD)
ICD SECTION VI – INDIA, SRILANKA AND NEPAL
CONVOCATION AND ANNUAL MEETING - 2025</h4>
<h5 className='text-center mt-2'>SCIENTIFIC PAPER – BEST PAPER PRIZES</h5>

   <ul className='mx-auto mt-2'>
    <li>
        FACULTY PRESENTATION

    </li>
    <li>
   ORAL AND MAXILLOFACIAL SURGERY

    </li>
    <li>
    PROSTHODONTICS, CROWN AND BRIDGE

    </li>
    <li>
CONSERVATIVE DENTISTRY & ENDODONTICS 
    </li>
    <li>
PERIODONTOLOGY
    </li>
    <li style={{textTransform:"uppercase"}}>
Orthodontics and Dentofacial Orthopedics

    </li>
    <li>
ORAL MEDICINE AND RADIOLOGY 
    </li>
    <li>
PEDIATRIC DENTISTRY 

    </li>
    <li>
PUBLIC HEALTH DENTISTRY
    </li>
    <li>
ORAL PATHOLOGY & MICROBIOLOGY

    </li>

   </ul>
<a href="./Scientific paper prizes.pdf" target="_blank" rel="noopener noreferrer"  className="py-3 px-2 rounded"
              style={{
                textDecoration: "none",
                color: "white",
                width: "30%",
                margin: "0 auto",
                textAlign:"center",
                backgroundColor: "#147745",
              }} >

        View Price Winners
    </a>
        </div>
     </div>
     <div className='col-lg-6 col-12'>
   <div className='card p-3'>
            <h4 className='text-center'>INTERNATIONAL COLLEGE OF DENTISTS (ICD)
ICD SECTION VI – INDIA, SRILANKA AND NEPAL
CONVOCATION AND ANNUAL MEETING - 2025</h4>
<h5 className='text-center mt-2'>SCIENTIFIC POSTER – BEST POSTER PRIZES</h5>

   <ul className='mx-auto mt-2'>

    <li>
   ORAL AND MAXILLOFACIAL SURGERY

    </li>
    <li>
    PROSTHODONTICS, CROWN AND BRIDGE

    </li>
    <li>
CONSERVATIVE DENTISTRY & ENDODONTICS 
    </li>
    <li>
PERIODONTOLOGY
    </li>
    <li style={{textTransform:"uppercase"}}>
Orthodontics and Dentofacial Orthopedics

    </li>

    <li>
PEDIATRIC DENTISTRY 

    </li>
    <li>
BASIC SCIENCES
    </li>
    <li>
ORAL MEDICINE 

    </li>
    <li>
ORAL PATHOLOGY & MICROBIOLOGY

    </li>
    <li>
PUBLIC HEALTH DENTISTRY


    </li>

   </ul>


<a href="./Scientific poster prizes.pdf" target="_blank" rel="noopener noreferrer"  className="py-3 px-2 rounded"
              style={{
                textDecoration: "none",
                color: "white",
                width: "30%",
                margin: "0 auto",
                textAlign:"center",
                backgroundColor: "#147745",
              }} >

        View Price Winners
    </a>


        </div>
     </div>

    </div>

    </div>
  )
}

export default PriceWinner