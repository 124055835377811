import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Committee from "./pages/Committee/Committee";
import Contact from "./pages/Contact/Contact";
import Abstracts from "./pages/Abstract/Abstracts";
import Registraction from "./pages/Registration/Registration";
import Accommodation from "./pages/Accommodation/Accommodation";
import Layout from "./component/Layout";
import Login from "./pages/Loin/Login";
import Trade from "./pages/Trade/Trade";
import { Routes, Route, Navigate } from "react-router-dom";
import CaseReport from "./component/AbstractSubmission/CaseReport";
import Series from "./component/AbstractSubmission/Series";
import Scientificprogram from "./pages/scientificProgram/ScientificProgram";
import Guidelines from "./pages/Guidelines/Guidelines";
import "./App.css";
import Admin from "./pages/admin/AdminPage";
import AdminLogin from "./pages/admin/AdminLogin";
import { AuthProvider } from "./pages/admin/AuthProvider";
import ProtectedRoute from "./pages/admin/ProtectedRoutes";
import Venue from "./pages/venue/Venue";
import UpdatePage from "./pages/update/UpdatePage";
import ScientificPresentation from "./pages/ScientificPresentation/ScientificPresentation";
import PriceWinner from "./pages/PriceWinner";
import Gallery from "./pages/Gallery";
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="committee" element={<Committee />} />
            <Route path="registration" element={<Registraction />} />
            <Route path="Abstracts" element={<Abstracts />} />
            <Route path="PriceWinner" element={<PriceWinner />} />
            <Route path="trade" element={<Trade />} />
            <Route path="contact" element={<Contact />} />
            <Route path="casereport" element={<CaseReport />} />
            <Route path="series" element={<Series />} />
            <Route path="scientificprogram" element={<Scientificprogram />} />
            <Route path="guidelines" element={<Guidelines />} />
            <Route element={<ProtectedRoute />}>
              <Route path="dashboard" element={<Admin />} />
            </Route>
            <Route path="scientificpresentation" element={<ScientificPresentation />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="update" element={<UpdatePage />} />
            <Route path="login" element={<AdminLogin />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
