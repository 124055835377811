import React from 'react'

const Gallery = () => {
  return (
   <div className='container-fluid col-10'>
    <div className='row py-5'>
     <div className='col-lg-6 col-12'>
        <div className='card p-3'>
            <h4 className='text-center'>INTERNATIONAL COLLEGE OF DENTISTS (ICD)
ICD SECTION VI – INDIA, SRILANKA AND NEPAL
CONVOCATION AND ANNUAL MEETING - 2025</h4>
<h5 className='text-center mt-2'>Day - 1 ICD conference photos</h5>

<a href="https://drive.google.com/drive/folders/11Zns15QcWZyt9hD08HyovJqdAEgQ2ew4" target="_blank" rel="noopener noreferrer"  className="py-3 mt-4 px-2 rounded"
              style={{
                textDecoration: "none",
                color: "white",
                width: "30%",
                margin: "0 auto",
                textAlign:"center",
                backgroundColor: "#147745",
              }} >

        View Photos
    </a>
        </div>
     </div>
     <div className='col-lg-6 col-12'>
        <div className='card p-3'>
            <h4 className='text-center'>INTERNATIONAL COLLEGE OF DENTISTS (ICD)
ICD SECTION VI – INDIA, SRILANKA AND NEPAL
CONVOCATION AND ANNUAL MEETING - 2025</h4>
<h5 className='text-center mt-2'>Day - 2 ICD conference photos</h5>

<a href="https://drive.google.com/drive/folders/1hnJfXFAJBwJAOmNqk-UGcK61BQ78BXRw" target="_blank" rel="noopener noreferrer"  className="py-3 mt-4 px-2 rounded"
              style={{
                textDecoration: "none",
                color: "white",
                width: "30%",
                margin: "0 auto",
                textAlign:"center",
                backgroundColor: "#147745",
              }} >

        View Photos
    </a>
        </div>
     </div>


    </div>

    </div>
  )
}

export default Gallery